
import {
  defineComponent,
  toRefs,
  ref,
  watch,
  PropType
  // onMounted,
  // onUnmounted
} from 'vue';
// import { ElMessage } from 'element-plus';
import {
  UploadFilled
  // VideoPause,
  // VideoPlay
} from '@element-plus/icons';
import {
  FileResultHandler,
  UploadFile,
  ElFile
} from 'element-plus/es/components/upload/src/upload.type';

import {
  Uploader,
  UploadStatus
} from './utils';
import { VideoType } from '@/services/api';

export interface UploadEvent {
  file: ElFile
  uploader: Uploader
  index: number
  source: string
  path: string
};

export interface VideoItem {
  file?: ElFile
  uploader?: Uploader
  progress?: number
  status?: UploadStatus
  previewSource?: string
  path?: string
}

const StatusMap: Record<UploadStatus, string> = {
  executing: '上傳中...',
  pause: '暫停',
  finished: '完成上傳',
  error: '發生錯誤，請重試一次'
};

export default defineComponent({
  components: {
    UploadFilled
    // VideoPause,
    // VideoPlay
  },
  props: {
    type: {
      type: String,
      required: true
    },
    sources: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    upload: (_event: UploadEvent) => true
  },
  setup(props, { emit }) {
    const { sources, multiple, type } = toRefs(props);
    const videoList = ref<VideoItem[]>(
      sources.value.map(source => ({
        progress: source ? 100 : 0,
        previewSource: source
      }))
    );
    watch(sources, (sources) => {
      videoList.value = sources.map(source => ({
        progress: source ? 100 : 0,
        previewSource: source
      }));
    });

    // const pauseUpload = ({ index }: { index: number }) => {
    //   const { uploader } = videoList.value[index];
    //   uploader.pause();
    // };

    // const resumeUpload = ({ index }: { index: number }) => {
    //   const { uploader } = videoList.value[index];
    //   uploader.resume();
    // };

    // const handleOffline = () => {
    //   ElMessage.warning('Offline! Please checking the network cables, modem and router or reconnecting to Wi-Fi.');

    //   videoList.value.forEach(videoItem => {
    //     if (videoItem.uploader && videoItem.status === UploadStatus.EXECUTING) {
    //       videoItem.uploader.pause();
    //     }
    //   });
    // };

    // const handleOnline = () => {
    //   ElMessage.success('Online! Back to work!');

    //   videoList.value.forEach(videoItem => {
    //     if (videoItem.uploader && videoItem.status === UploadStatus.PAUSE) {
    //       videoItem.uploader.resume();
    //     }
    //   });
    // };

    // onMounted(() => {
    //   window.addEventListener('offline', handleOffline);
    //   window.addEventListener('online', handleOnline);
    // });

    // onUnmounted(() => {
    //   window.removeEventListener('offline', handleOffline);
    //   window.removeEventListener('online', handleOnline);
    // });

    const handleFileChange: FileResultHandler = async({ raw: file }: UploadFile) => {
      // if (!navigator.onLine) {
      //   handleOffline();
      //   return;
      // }

      let index = 0;
      const uploader = new Uploader(
        file,
        {
          meta: {
            type: type.value as VideoType
          },
          onProgress(percentage) {
            videoList.value[index].progress = percentage;
          },
          onStatusChange(status) {
            videoList.value[index].status = status;
          },
          onSuccess({ signedUrl, key }) {
            videoList.value[index].previewSource = signedUrl;
            videoList.value[index].path = key;

            // TODO: should be uploaded
            emit('upload', {
              source: signedUrl,
              path: key,
              file,
              uploader,
              index
            } as UploadEvent);
          }
        }
      );

      const videoItem: VideoItem = {
        file,
        progress: uploader.progress,
        status: uploader.status,
        // uuid: uuidV4(),
        uploader
      };

      if (!multiple.value) {
        videoList.value[index] = videoItem;
      } else {
        const length = videoList.value.push(videoItem);
        index = length - 1;
      }

      uploader.start();
    };

    return {
      videoList,
      UploadStatus,
      handleFileChange,
      // pauseUpload,
      // resumeUpload,
      StatusMap
    };
  }
});
