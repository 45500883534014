import axios from 'axios';
import PQueue from 'p-queue/dist';

import {
  createPresignedUrls,
  completeMultipartUpload,
  CompleteMultipartUploadResponse,
  VideoType
} from '@/services/api';

const DEFAULT_CHUNK_SIZE = 10 * 1024 * 1024; // 10Mb
const DEFAULT_CONCURRENCY = 10;

export function createFileChunk(file: File, size = DEFAULT_CHUNK_SIZE) {
  const fileChunks: Blob[] = [];

  let current = 0;
  while (current < file.size) {
    fileChunks.push(file.slice(current, current + size));
    current += size;
  }

  return fileChunks;
}

interface UploadChunksOptions {
  chunks: Blob[]
  urls: string[]
  concurrency?: number
  onProgress?(percentage: number): void
}

interface UploadChunksResponse {
  eTags: string[]
}

export async function uploadChunks({
  chunks,
  urls,
  concurrency = DEFAULT_CONCURRENCY,
  onProgress
}: UploadChunksOptions): Promise<UploadChunksResponse> {
  const request = axios.create();
  const queue = new PQueue({ concurrency });

  const totalSize = chunks.reduce((total, chunk) => chunk.size + total, 0);

  const loadedList: number[] = [];
  const responses = await queue.addAll(
    chunks.map((chunk, index) =>
      () => request.put(
        urls[index],
        chunk,
        {
          onUploadProgress({ loaded }: ProgressEvent) {
            loadedList[index] = loaded;

            const totalLoaded = loadedList.reduce((totalLoaded, currentLoaded) => totalLoaded + currentLoaded);
            const percentage = Math.floor(totalLoaded / totalSize * 100);

            onProgress && onProgress(percentage);
          }
        }
      ))
  );

  return {
    eTags: responses.map((part) => part.headers.etag as string)
  };
}

export enum UploadStatus {
  EXECUTING = 'executing',
  PAUSE = 'pause',
  FINISHED = 'finished',
  ERROR = 'error'
}

export type UploadResponse = CompleteMultipartUploadResponse['data'];

interface FileMeta {
  uploadChunkSize?: number
  concurrency?: number
  type?: VideoType
}

interface UploadOptions {
  meta?: FileMeta
  onStatusChange?(status: UploadStatus): void
  onProgress?(percentage: number): void
  onSuccess?(response: UploadResponse): void
  onError?(error: Error): void
}

const DEFAULT_PROGRESS = 0;

export class Uploader {
  status = UploadStatus.PAUSE;
  progress = DEFAULT_PROGRESS;
  file: File;
  s3FileKey: string;
  options: UploadOptions;

  constructor(file: File, options: UploadOptions = {}) {
    this.file = file;
    this.options = {
      meta: {
        uploadChunkSize: DEFAULT_CHUNK_SIZE,
        concurrency: DEFAULT_CONCURRENCY,
        type: VideoType.VIDEO,
        ...options.meta
      },
      ...options
    };
  }

  setStatus(status: UploadStatus) {
    const { onStatusChange } = this.options;

    this.status = status;
    onStatusChange && onStatusChange(this.status);
  }

  setProgress(percentage: number) {
    const { onProgress } = this.options;

    this.progress = percentage;
    onProgress && onProgress(this.progress);
  }

  setSuccess(response: UploadResponse) {
    const { onSuccess } = this.options;

    this.setProgress(100);
    this.setStatus(UploadStatus.FINISHED);
    onSuccess && onSuccess(response);
  }

  async start() {
    if (this.status === UploadStatus.FINISHED) return;

    this.setStatus(UploadStatus.EXECUTING);

    // 1. create file chunks
    const { file, options } = this;
    const fileChunks = createFileChunk(file, options.meta.uploadChunkSize);

    try {
      // 2. create presigned URLs
      const { data: { urls, uploadId, filename } } = await createPresignedUrls({
        data: {
          filetype: file.type.split('/')[1],
          parts: fileChunks.length,
          type: options.meta.type
        }
      });
      this.s3FileKey = filename;

      // 3. upload chunks by URLs
      const { eTags } = await uploadChunks({
        chunks: fileChunks,
        urls: urls,
        onProgress: (percentage) => {
          this.setProgress(percentage - 1);
        },
        concurrency: options.meta.concurrency
      });

      // 4. complete multipart upload
      const { data } = await completeMultipartUpload({
        data: {
          filename,
          etags: eTags,
          uploadId
        }
      });

      this.setSuccess(data);
    } catch (error) {
      this.setProgress(DEFAULT_PROGRESS);
      this.setStatus(UploadStatus.ERROR);
    }
  }
}
