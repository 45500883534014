
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  computed,
  onMounted,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';
// import { Rules } from 'async-validator';
// import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader-v2.vue';
import VideoUploader, { UploadEvent } from '@/components/video-uploader-v2/Index.vue';
import SectionLayout from '@/components/SectionLayout.vue';
import {
  getAgents,
  getActors,
  getGenres,
  getDirectors,
  getTags,
  getClassifications,
  MAX_PER_PAGE,
  CreateWorkData,
  ResponseError,
  UPDATED_SUCCESSFULLY_TEXT,
  CREATED_SUCCESSFULLY_TEXT
} from '@/services/api';
import {
  useCreateWork,
  useUpdateWork,
  useWork
} from '@/composables/api';
import { Actor } from '@/interfaces/Actor';
import { Agent } from '@/interfaces/Agent';
import { Tag } from '@/interfaces/Tag';
import { Director } from '@/interfaces/Director';
import { Classification } from '@/interfaces/Classification';
import { Genre } from '@/interfaces/Genre';
import useFormErrors, { isValidCharacterLength } from '@/utils/validate';
import { Photo } from '@/interfaces/Photo';
import { normalizeRequestData } from '@/utils/normalization';
import { WorkStatus } from '@/interfaces';
import { Rule, RuleItem } from 'async-validator';
import dayjs from 'dayjs';
import { includes } from 'lodash';

const DEFAULT_FORM_VALUES: CreateWorkData & {
  coverPhoto?: Photo
  mobileCoverPhoto?: Photo
  captionCoverPhoto?: Photo
  noExposeCoverPhoto?: Photo
  noExposeCaptionCoverPhoto?: Photo
  noExposeMobileCoverPhoto?: Photo
  titlePhoto?: Photo
} = {
  agentId: null,
  name: {
    'zh-TW': '',
    'en-US': ''
  },
  number: '',
  coverPhotoId: null,
  mobileCoverPhotoId: null,
  captionCoverPhotoId: null,
  titlePhotoId: null,
  video: '',
  videoSource: '',
  trailer: '',
  trailerSource: '',
  noExposeTrailer: '',
  noExposeTrailerSource: '',
  isExclusive: false,
  classificationIds: [],
  genreIds: [],
  actorIds: [],
  directorIds: [],
  tagIds: [],
  introduction: {
    'zh-TW': '',
    'en-US': ''
  },
  publishedAt: '',
  copyrightExpiredAt: '',
  status: null,
  noExposeCoverPhotoId: null,
  noExposeMobileCoverPhotoId: null,
  noExposeCaptionCoverPhotoId: null
};

const REQUIRED_RULE: Rule = {
  required: true,
  message: '此為必填欄位'
};

const rules = {
  name: {
    'zh-TW': [REQUIRED_RULE],
    'en-US': [REQUIRED_RULE]
  },
  trailerSource: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  videoSource: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  classificationIds: [REQUIRED_RULE],
  number: [REQUIRED_RULE],
  captionCoverPhoto: [REQUIRED_RULE],
  noExposeCaptionCoverPhoto: [REQUIRED_RULE],
  noExposeTrailerSource: [{
    required: true,
    message: '此為必填欄位',
    trigger: 'change'
  }],
  introduction: {
    'zh-TW': [{
      required: true,
      validator: (rule: RuleItem, context: string) => {
        if (!rule.required && !context) {
          return true;
        }

        return isValidCharacterLength(context);
      }
    }],
    'en-US': [{
      validator: (rule: RuleItem, context: string) => {
        if (!context) {
          return true;
        }

        return isValidCharacterLength(context, 130);
      }
    }]
  },
  actorIds: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  genreIds: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  tagIds: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ],
  publishedAt: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    VideoUploader,
    SectionLayout
  },
  setup(props) {
    const { isEdit } = toRefs(props);

    const workId = useRoute().params.id as string;
    const router = useRouter();

    const { data, dataUpdatedAt } = useWork({ workId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const isFormLoading = ref(true);

    const { formErrors, bindFormItemError } = useFormErrors();

    const defaultEndTime = ref(new Date(2000, 2, 1, 23, 59, 59));

    const agents = ref<Agent[]>([]);
    const actors = ref<Actor[]>([]);
    const directors = ref<Director[]>([]);
    const classifications = ref<Classification[]>([]);
    const genres = ref<Genre[]>([]);
    const tags = ref<Tag[]>([]);

    const coverPhotoSource = computed(() => formValues.value.coverPhoto?.path);
    const mobileCoverPhotoSource = computed(() => formValues.value.mobileCoverPhoto?.path);
    const noExposeMobileCoverPhotoSource = computed(() => formValues.value.noExposeMobileCoverPhoto?.path);
    const captionCoverPhotoSource = computed(() => formValues.value.captionCoverPhoto?.path);
    const titlePhotoSource = computed(() => formValues.value.titlePhoto?.path);
    const noExposeCoverPhotoSource = computed(() => formValues.value.noExposeCoverPhoto?.path);
    const noExposeCaptionCoverPhotoSource = computed(() => formValues.value.noExposeCaptionCoverPhoto?.path);

    const { isLoading: isCreating, mutate: create } = useCreateWork();
    const { isLoading: isUpdating, mutate: update } = useUpdateWork();

    const fetchAgents = async() => {
      agents.value = (await getAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchActors = async() => {
      actors.value = (await getActors({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchDirectors = async() => {
      directors.value = (await getDirectors({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications()).data;
    };

    const fetchGenres = async() => {
      genres.value = (await getGenres({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const checkDateRange = () => {
      return (dayjs(formValues.value.copyrightExpiredAt).valueOf() < dayjs(formValues.value.publishedAt).valueOf());
    };

    watch(formValues.value, () => {
      if (checkDateRange()) formValues.value.copyrightExpiredAt = '';
    });

    watch(dataUpdatedAt, () => {
      const work = data.value.data;

      formValues.value = {
        ...work,
        coverPhotoId: work.coverPhoto.id,
        mobileCoverPhotoId: work.mobileCoverPhoto?.id,
        captionCoverPhotoId: work.captionCoverPhoto.id,
        titlePhotoId: work.titlePhoto.id,
        agentId: work.agent?.id,
        name: {
          'en-US': work.name['en-US'],
          'zh-TW': work.name['zh-TW']
        },
        number: work.number,
        video: work.video.video,
        videoSource: work.video.videoSource,
        trailer: work.trailer,
        trailerSource: work.trailerSource,
        noExposeTrailer: work.noExposeTrailer,
        noExposeTrailerSource: work.noExposeTrailerSource,
        isExclusive: work.isExclusive,
        classificationIds: work.classifications.map(classification => classification.id),
        genreIds: work.genres.map(genre => genre.id),
        actorIds: work.actors.map(actor => actor.id),
        directorIds: work.directors.map(director => director.id),
        tagIds: work.tags.map(tag => tag.id),
        introduction: {
          'en-US': work.introduction['en-US'],
          'zh-TW': work.introduction['zh-TW']
        },
        publishedAt: work.publishedAt,
        status: work.status,
        copyrightExpiredAt: work.copyrightExpiredAt,
        noExposeCoverPhotoId: work.noExposeCoverPhoto?.id,
        noExposeMobileCoverPhotoId: work.noExposeMobileCoverPhoto?.id,
        noExposeCaptionCoverPhotoId: work.noExposeCaptionCoverPhoto?.id
      };

      isFormLoading.value = false;
    });

    onMounted(async() => {
      if (!isEdit.value) {
        isFormLoading.value = false;
      }

      fetchAgents();
      fetchActors();
      fetchDirectors();
      fetchGenres();
      fetchTags();
      fetchClassifications();
    });

    const handleCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.coverPhoto = image;
      formValues.value.coverPhotoId = image.id;
    };

    const handleMobileCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.mobileCoverPhoto = image;
      formValues.value.mobileCoverPhotoId = image.id;
    };

    const handleNoExposeMobileCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.noExposeMobileCoverPhoto = image;
      formValues.value.noExposeMobileCoverPhotoId = image.id;
    };

    const handleCaptionCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.captionCoverPhoto = image;
      formValues.value.captionCoverPhotoId = image.id;
    };

    const handleNoExposeCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.noExposeCoverPhoto = image;
      formValues.value.noExposeCoverPhotoId = image.id;
    };

    const handleNoExposeCaptionCoverPhotoCrop = (image: UploadImageEvent) => {
      formValues.value.noExposeCaptionCoverPhoto = image;
      formValues.value.noExposeCaptionCoverPhotoId = image.id;
    };

    const handleTitlePhotoCrop = (image: UploadImageEvent) => {
      formValues.value.titlePhoto = image;
      formValues.value.titlePhotoId = image.id;
    };

    const handleTrailerUpload = ({ source, path }: UploadEvent) => {
      formValues.value.trailerSource = source;
      formValues.value.trailer = path;
    };

    const handleNoExposeTrailerUpload = ({ source, path }: UploadEvent) => {
      formValues.value.noExposeTrailerSource = source;
      formValues.value.noExposeTrailer = path;
    };

    const handleVideoUpload = ({ source, path }: UploadEvent) => {
      formValues.value.videoSource = source;
      formValues.value.video = path;
    };

    const disabledStartDate = (datesOnCalendar: any) => {
      const today = new Date();
      const yesterday = new Date().setDate(today.getDate() - 1);
      return datesOnCalendar.getTime() < yesterday;
    };

    const disabledEndDate = (datesOnCalendar: any): Boolean => {
      const startDateTimestamp = dayjs(formValues.value.publishedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };

    const stripQueryString = (url: string) => {
      return url.split('?')[0];
    };

    const submitForm = (status: WorkStatus) => {
      formErrors.value = {};
      formValues.value.status = status;
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (!valid) return;

        if (isEdit.value) {
          update({
            workId,
            data: normalizeRequestData({
              ...formValues.value,
              videoSource: stripQueryString(formValues.value.videoSource),
              trailerSource: stripQueryString(formValues.value.trailerSource),
              noExposeTrailerSource: stripQueryString(formValues.value.noExposeTrailerSource)
            }, { patternedStringKeys: ['agentId'] })
          }, {
            onSuccess() {
              ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

              router.go(-1);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.message;
            }
          });

          return;
        }

        create(
          {
            data: normalizeRequestData({
              ...formValues.value,
              videoSource: stripQueryString(formValues.value.videoSource),
              trailerSource: stripQueryString(formValues.value.trailerSource),
              noExposeTrailerSource: stripQueryString(formValues.value.noExposeTrailerSource)
            })
          },
          {
            onSuccess() {
              ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

              formValues.value = DEFAULT_FORM_VALUES;

              router.go(-1);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.message;
            }
          });
      });
    };

    const handleClassificationIdsChange = (e) => {
      if (includes(e, 4)) {
        formValues.value.classificationIds = [4];
      }
    };

    return {
      tags,
      rules,
      actors,
      agents,
      genres,
      formRef,
      directors,
      WorkStatus,
      isCreating,
      isUpdating,
      formErrors,
      formValues,
      isFormLoading,
      defaultEndTime,
      classifications,
      coverPhotoSource,
      titlePhotoSource,
      mobileCoverPhotoSource,
      captionCoverPhotoSource,
      noExposeCoverPhotoSource,
      noExposeMobileCoverPhotoSource,
      noExposeCaptionCoverPhotoSource,
      submitForm,
      disabledEndDate,
      disabledStartDate,
      handleVideoUpload,
      bindFormItemError,
      handleTrailerUpload,
      handleCoverPhotoCrop,
      handleTitlePhotoCrop,
      handleMobileCoverPhotoCrop,
      handleCaptionCoverPhotoCrop,
      handleNoExposeTrailerUpload,
      handleNoExposeCoverPhotoCrop,
      handleNoExposeMobileCoverPhotoCrop,
      handleNoExposeCaptionCoverPhotoCrop,
      handleClassificationIdsChange
    };
  }
});
